import styled from 'styled-components';

import Icon from '@/components/common/Icon/Icon';
import media from '@/modules/media';

/*
  Being used by these 2 files, be careful when doing any changes
  `/pages/logout-with-error.tsx`
  `/components/common/ClientErrorPage/ClientErrorPage.tsx`
*/

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Bubble = styled.div`
  position: relative;
  max-width: 704px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Error = styled.p`
  font-size: ${(props) => props.theme.space.xxl};
  font-weight: 900;
  color: ${({ theme }) => theme.color.gray900};
  z-index: 2;
  margin-top: ${(props) => props.theme.space.xxl};
  margin-bottom: ${(props) => props.theme.space.lg};

  ${media.phone} {
    font-size: ${(props) => props.theme.font.lg};
  }
`;

export const Countdown = styled.p`
  font-size: ${(props) => props.theme.space.xxl};
  font-weight: 900;
  color: ${({ theme }) => theme.color.gray900};
  z-index: 2;

  ${media.phone} {
    font-size: ${(props) => props.theme.font.lg};
  }
`;

export const InformativeText = styled.p`
  padding: 0 ${(props) => props.theme.space.xxxl};
  text-align: center;
  font-size: ${(props) => props.theme.space.lg};
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray900};
  line-height: 24px;
  z-index: 2;
  margin-bottom: ${(props) => props.theme.space.xxxl};
`;

export const BubbleIcon = styled(Icon)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const BackToLogin = styled.div`
  width: 200px;

  ${media.phone} {
    width: 140px;
    font-size: ${(props) => props.theme.font.md};
  }
`;
