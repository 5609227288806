import React, { createContext, useContext, useMemo, useState } from 'react';

type ExamContextType = {
  examHasStatus: boolean;
  setExamHasStatus: (isOpen: boolean) => void;
  resetToDefault: () => void;
};

const defaultState = {
  examHasStatus: false,
};

const ExamContext = createContext<ExamContextType>({
  examHasStatus: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setExamHasStatus: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetToDefault: () => {},
});

type ExamProviderProps = {
  children: React.ReactNode;
};

const ExamProvider = ({ children }: ExamProviderProps) => {
  const [examHasStatus, setExamHasStatus] = useState(false);

  const resetToDefault = () => {
    setExamHasStatus(defaultState.examHasStatus);
  };

  const contextValue = useMemo(
    () => ({
      examHasStatus,
      setExamHasStatus,
      resetToDefault,
    }),
    [examHasStatus, setExamHasStatus]
  );

  return (
    <ExamContext.Provider value={contextValue}>{children}</ExamContext.Provider>
  );
};

export const useExamContext = () => useContext(ExamContext);

export { ExamProvider };
