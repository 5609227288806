import React, { createContext, useContext, useMemo, useState } from 'react';

type SubjectGoal = {
  name: string;
  value: string;
  lastBaselineValue: number;
  yearValue: number;
};
type GoalsContextType = {
  resetToDefault: () => void;
  hasGoals: boolean;
  setHasGoals: (hasGoals: boolean) => void;
  subjectGoal: SubjectGoal | null;
  setSubjectGoal: (subjectGoal: SubjectGoal | null) => void;
};

const defaultState = {
  hasGoals: false,
};

const GoalsContext = createContext<GoalsContextType>({
  hasGoals: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHasGoals: () => {},
  subjectGoal: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSubjectGoal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetToDefault: () => {},
});

type GoalsProviderProps = {
  children: React.ReactNode;
};

const GoalsProvider = ({ children }: GoalsProviderProps) => {
  const [hasGoals, setHasGoals] = useState(false);
  const [subjectGoal, setSubjectGoal] = useState<SubjectGoal | null>(null);

  const resetToDefault = () => {
    setHasGoals(defaultState.hasGoals);
  };

  const contextValue = useMemo(
    () => ({
      resetToDefault,
      hasGoals,
      setHasGoals,
      subjectGoal,
      setSubjectGoal,
    }),
    [hasGoals, setHasGoals, subjectGoal, setSubjectGoal]
  );

  return (
    <GoalsContext.Provider value={contextValue}>
      {children}
    </GoalsContext.Provider>
  );
};

export const useGoalsContext = () => useContext(GoalsContext);

export { GoalsProvider };
