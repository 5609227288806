/* eslint-disable react/jsx-no-constructed-context-values */

import { ChakraProvider } from '@chakra-ui/provider';
import { createStandaloneToast } from '@chakra-ui/toast';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import {
  defaultShouldDehydrateQuery,
  QueryClient,
} from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import {
  PersistQueryClientProvider,
  removeOldestQuery,
} from '@tanstack/react-query-persist-client';
import { logger } from 'modules/logger';
import ErrorBoundary from '@/components/common/ErrorBoundary';
import ProtectedRoute from '@/components/common/ProtectedRoute/ProtectedRoute';
import { CountryType } from '@/components/common/RegistrationHeader/types';
import { GlobalProvider } from '@/contexts/GlobalContext';
import { AuthProvider } from '@/contexts/AuthContext';
import { DiagnosticProvider } from '@/contexts/DiagnosticContext/DiagnosticContext';
import { RegistrationProvider } from '@/contexts/RegistrationContext/RegistrationContext';
import { SchoolAdminProvider } from '@/contexts/SchoolAdminContext/SchoolAdminContext';
import { TeacherProvider } from '@/contexts/TeacherContext';
import { TeacherModuleBuilderContextProvider } from '@/contexts/TeacherModuleBuilderContext';
import { UserProfileProvider } from '@/contexts/UserProfileContext';
import { getEnvVars } from '@/modules/common';
import { LearnerProfileDto } from '@/store/user-profile/types';
import { ParentProvider } from '@/contexts/ParentContext';
import { GoalsProvider } from '@/contexts/GoalsContext';
import { FTUEProvider } from '@/contexts/FTUEContext';

import { ExamProvider } from '@/contexts/ExamContext';
import Fonts from '../components/common/Fonts/Fonts';
import { LearnersContext } from '../contexts/LearnersContext';
import {
  CountryContext,
  countryInitialValues,
} from '../contexts/LocalizationContext';
import theme from '../styles/theme';

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const { ToastContainer } = createStandaloneToast();

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Ensure all environmental variables are non empty
  const envVars = getEnvVars();
  const emptyEnvVars = Object.entries(envVars)
    .filter((envVar) => !envVar[1])
    .map((envVar) => `${envVar[0]}=${envVar[1]}`);
  if (emptyEnvVars.length > 0) {
    const errorMessage = `Empty Environmental Variable(s): ${emptyEnvVars}`;
    logger.fatal(errorMessage);
  }

  // global settings for react-query
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: 1000 * 60 * 15, // 15 minutes
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  const [country, setCountry] = useState<CountryType>(countryInitialValues);
  const [learners, setLearners] = useState<LearnerProfileDto[]>([]);

  useEffect(() => {
    if (localStorage.getItem('learners')) {
      setLearners(JSON.parse(localStorage.getItem('learners') || ''));
    }
  }, []);

  const setCountryInfo = (c: CountryType) => {
    setCountry(c);
    localStorage.setItem('country', JSON.stringify(c));
  };

  const setLearnersInfo = (l: LearnerProfileDto[]) => {
    setLearners(l);
    localStorage.setItem('learners', JSON.stringify(l));
  };

  const { getLayout } = Component;

  const syncStoragePersister = createSyncStoragePersister({
    storage: global.localStorage,
    retry: removeOldestQuery,
    key: `STUDYHALL_RQ_OFFLINE_CACHE`,
    throttleTime: 1000,
    serialize: JSON.stringify,
    deserialize: JSON.parse,
  });

  // Add a new state for checking if the component has been mounted
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // Set isMounted to true after the component has been mounted
    setIsMounted(true);
  }, []);

  // Do not render the main component until the component has been mounted on the client-side
  if (!isMounted) {
    return null;
  }

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: syncStoragePersister,
        dehydrateOptions: {
          shouldDehydrateQuery: (query) => {
            return (
              defaultShouldDehydrateQuery(query) && query.meta?.cache !== false
            );
          },
        },
      }}
    >
      <Head>
        <title>Study Hall</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
      </Head>
      <ChakraProvider resetCSS theme={theme}>
        <Fonts />
        <ThemeProvider
          theme={{
            ...theme.custom,
            ...{ color: theme.colors.brand },
            ...{ learnerStatusColors: theme.colors.learnerStatusColors },
          }}
        >
          <AuthProvider>
            <UserProfileProvider>
              <RegistrationProvider>
                <CountryContext.Provider value={{ country, setCountryInfo }}>
                  <ErrorBoundary>
                    <LearnersContext.Provider
                      value={{ learners, setLearnersInfo }}
                    >
                      <GlobalProvider>
                        <GoalsProvider>
                          <ExamProvider>
                            <ParentProvider>
                              <SchoolAdminProvider>
                                <TeacherProvider>
                                  <DiagnosticProvider>
                                    <TeacherModuleBuilderContextProvider>
                                      <ProtectedRoute>
                                        <FTUEProvider>
                                          {getLayout ? (
                                            getLayout(
                                              <Component {...pageProps} />
                                            )
                                          ) : (
                                            <Component {...pageProps} />
                                          )}
                                          <ToastContainer />
                                        </FTUEProvider>
                                      </ProtectedRoute>
                                    </TeacherModuleBuilderContextProvider>
                                  </DiagnosticProvider>
                                </TeacherProvider>
                              </SchoolAdminProvider>
                            </ParentProvider>
                          </ExamProvider>
                        </GoalsProvider>
                      </GlobalProvider>
                    </LearnersContext.Provider>
                  </ErrorBoundary>
                </CountryContext.Provider>
              </RegistrationProvider>
            </UserProfileProvider>
          </AuthProvider>
        </ThemeProvider>
      </ChakraProvider>
      <ReactQueryDevtools position="bottom-right" />
    </PersistQueryClientProvider>
  );
};

export default MyApp;
