import Image from 'next/image';
import styled from 'styled-components';

import { IconOptionsType } from '@/components/common/Icon/types';

type WrapperProps = {
  size:
    | 'xs'
    | 'xsm'
    | 'sm'
    | 'smd'
    | 'md'
    | 'mdl'
    | 'lg'
    | '2lg'
    | 'lgm'
    | 'lgx'
    | 'xl'
    | 'xxl'
    | '2xl'
    | '3xl'
    | 'paymentMethod-sm'
    | 'paymentMethod-md'
    | 'paymentMethod-lg'
    | 'book-pile'
    | 'parentRegistration'
    | 'registrationStep2Decorative'
    | 'invitation-icon'
    | 'penguinLogo'
    | 'pretestLogo'
    | 'examPapersLogo'
    | 'header-status-logo'
    | 'learner-portal-logo'
    | 'no-friends-search'
    | 'status-sm'
    | 'logoFull'
    | 'handred'
    | 'wordCircle'
    | 'clock'
    | 'loadedClock'
    | 'clockSmall'
    | 'circleBar'
    | 'turtle'
    | 'jaguar'
    | 'rabbit'
    | 'exam-header-logo'
    | 'race-option-icon'
    | 'chatBot'
    | 'geogebra'
    | 'geogebraSmall';

  color: keyof IconOptionsType['color'];
  $hoverColor?: keyof IconOptionsType['hoverColor'];
};

const options: IconOptionsType = {
  color: {
    default: 'filter: none',
    white: 'filter: invert(1);',
    gray: 'filter: invert(0.8);',
    whiteSecondary: 'filter: brightness(0) invert(1);',
    gray50:
      'filter: brightness(0) saturate(100%) invert(93%) sepia(7%) saturate(107%) hue-rotate(175deg) brightness(103%) contrast(91%);',
    gray100:
      'filter: invert(96%) sepia(4%) saturate(397%) hue-rotate(180deg) brightness(95%) contrast(94%);',
    gray300:
      'filter: invert(94%) sepia(5%) saturate(558%) hue-rotate(181deg) brightness(89%) contrast(95%);',
    gray350:
      'filter: brightness(0) saturate(100%) invert(89%) sepia(13%) saturate(169%) hue-rotate(174deg) brightness(87%) contrast(84%);',
    gray400:
      'filter: invert(62%) sepia(7%) saturate(643%) hue-rotate(204deg) brightness(91%) contrast(93%);',
    lightGray: 'filter: invert(0.6);',
    gray500:
      'filter: brightness(0) saturate(100%) invert(70%) sepia(7%) saturate(320%) hue-rotate(176deg) brightness(84%) contrast(86%);',
    gray600:
      'filter: invert(42%) sepia(4%) saturate(516%) hue-rotate(173deg) brightness(97%) contrast(90%);',
    gray700:
      'filter: invert(21%) sepia(8%) saturate(429%) hue-rotate(169deg) brightness(93%) contrast(84%);',
    gray900:
      'filter: invert(4%) sepia(9%) saturate(4424%) hue-rotate(208deg) brightness(85%) contrast(96%);',
    primary300:
      'filter: invert(81%) sepia(11%) saturate(3237%) hue-rotate(314deg) brightness(116%) contrast(94%);',
    primary500:
      'filter: invert(80%) sepia(10%) saturate(7228%) hue-rotate(325deg) brightness(100%) contrast(92%);',
    primary700:
      'filter: invert(34%) sepia(96%) saturate(1298%) hue-rotate(10deg) brightness(106%) contrast(99%);',
    yellow800:
      'filter: invert(78%) sepia(90%) saturate(1522%) hue-rotate(354deg) brightness(98%) contrast(99%);',
    black: 'filter: invert(0);',
    red: 'filter: invert(50%) sepia(1006%) saturate(1114%) hue-rotate(-45deg) brightness(97%) contrast(300%);',
    blue: 'filter: invert(51%) sepia(17%) saturate(1572%) hue-rotate(168deg) brightness(87%) contrast(95%);',
    blue200:
      'filter: invert(26%) sepia(99%) saturate(2256%) hue-rotate(209deg) brightness(99%) contrast(94%);',
    blue800:
      'filter: invert(14%) sepia(99%) saturate(2844%) hue-rotate(212deg) brightness(92%) contrast(92%);',
    bluePrimary:
      'filter: invert(11%) sepia(82%) saturate(5794%) hue-rotate(210deg) brightness(74%) contrast(94%);',
    orange:
      'filter: invert(80%) sepia(53%) saturate(3765%) hue-rotate(335deg) brightness(98%) contrast(96%);',
    green:
      'filter: invert(56%) sepia(22%) saturate(692%) hue-rotate(115deg) brightness(86%) contrast(90%);',
    success700:
      'filter: brightness(0) saturate(100%) invert(36%) sepia(18%) saturate(5349%) hue-rotate(141deg) brightness(86%) contrast(80%);',
    purple:
      'filter: invert(48%) sepia(13%) saturate(1801%) hue-rotate(234deg) brightness(90%) contrast(89%);',
    onboarding:
      'filter: invert(56%) sepia(74%) saturate(347%) hue-rotate(333deg) brightness(101%) contrast(103%);',
    deepReading:
      'filter: invert(22%) sepia(61%) saturate(2072%) hue-rotate(201deg) brightness(92%) contrast(106%);',
    testing:
      'filter: invert(34%) sepia(21%) saturate(2019%) hue-rotate(123deg) brightness(95%) contrast(103%);',
    relaxing:
      'filter: invert(67%) sepia(56%) saturate(4255%) hue-rotate(144deg) brightness(98%) contrast(101%);',
    studying:
      'filter: invert(48%) sepia(36%) saturate(6520%) hue-rotate(8deg) brightness(102%) contrast(99%);',
    error700:
      'filter: invert(33%) sepia(94%) saturate(957%) hue-rotate(330deg) brightness(100%) contrast(95%);',
    error800:
      'filter: invert(33%) sepia(70%) saturate(3093%) hue-rotate(349deg) brightness(102%) contrast(86%);',
    pink800:
      'filter: brightness(0) saturate(100%) invert(48%) sepia(72%) saturate(2184%) hue-rotate(296deg) brightness(95%) contrast(86%);',
    turquoise900:
      'filter: invert(43%) sepia(95%) saturate(501%) hue-rotate(138deg) brightness(92%) contrast(103%);',
    ONBOARDING:
      'filter: invert(56%) sepia(74%) saturate(347%) hue-rotate(333deg) brightness(101%) contrast(103%);',
    DEEPREADING:
      'filter: invert(22%) sepia(61%) saturate(2072%) hue-rotate(201deg) brightness(92%) contrast(106%);',
    TESTING:
      'filter: invert(34%) sepia(21%) saturate(2019%) hue-rotate(123deg) brightness(95%) contrast(103%);',
    RELAXING:
      'filter: invert(67%) sepia(56%) saturate(4255%) hue-rotate(144deg) brightness(98%) contrast(101%);',
    STUDYING:
      'filter: invert(48%) sepia(36%) saturate(6520%) hue-rotate(8deg) brightness(102%) contrast(99%);',
  },
  hoverColor: {
    gray700:
      'filter: invert(21%) sepia(8%) saturate(429%) hue-rotate(169deg) brightness(93%) contrast(84%);',
    primary500:
      'filter: invert(84%) sepia(83%) saturate(6163%) hue-rotate(317deg) brightness(102%) contrast(91%)',
  },
  size: {
    xs: { width: 12, height: 12 },
    xsm: { width: 16, height: 16 },
    sm: { width: 20, height: 20 },
    smd: { width: 24, height: 24 },
    md: { width: 30, height: 30 },
    mdl: { width: 32, height: 32 },
    lg: { width: 36, height: 36 },
    lgm: { width: 40, height: 40 },
    lgx: { width: 48, height: 48 },
    xl: { width: 56, height: 56 },
    xxl: { width: 64, height: 64 },
    '2lg': { width: 72, height: 72 },
    '2xl': { width: 80, height: 80 },
    '3xl': { width: 152, height: 152 },
    handred: { width: 85, height: 40 },
    'paymentMethod-sm': { width: 34, height: 34 },
    'paymentMethod-md': { width: 46, height: 32 },
    'paymentMethod-lg': { width: 58, height: 40 },
    'book-pile': { width: 160, height: 267 },
    'invitation-icon': { width: 48, height: 48 },
    'header-status-logo': { width: 150, height: 32 },
    'learner-portal-logo': { width: 40, height: 40 },
    'no-friends-search': { width: 56, height: 56 },
    logoFull: { width: 180, height: 40 },
    'status-sm': { width: 16, height: 16 },
    penguinLogo: { width: 156, height: 56 },
    pretestLogo: { width: 122, height: 56 },
    examPapersLogo: { width: 112, height: 56 },
    parentRegistration: { width: 200, height: 470 },
    registrationStep2Decorative: { width: 266, height: 200 },
    wordCircle: { width: 37, height: 37 },
    clock: { width: 82, height: 75 },
    loadedClock: { width: 80, height: 80 },
    clockSmall: { width: 80, height: 80 },
    circleBar: { width: 80, height: 80 },
    turtle: { width: 56, height: 40 },
    rabbit: { width: 80, height: 40 },
    jaguar: { width: 56, height: 40 },
    'exam-header-logo': { width: 32, height: 32 },
    'race-option-icon': { width: 38, height: 40 },
    chatBot: { width: 40, height: 40 },
    geogebra: { width: 218, height: 32 },
    geogebraSmall: { width: 109, height: 16 },
  },
};

export const Wrapper = styled(Image).attrs((props: any) => {
  return {
    width:
      props.size && options.size[props.size as keyof typeof options.size].width,
    height:
      props.size &&
      options.size[props.size as keyof typeof options.size].height,
  };
})<WrapperProps>`
  ${(props) => props.size && options.size[props.size]};
  ${(props) => props.color && options.color[props.color]};
  ${(props) => props.onClick && 'cursor: pointer'};
  transform: none;
  &:hover {
    ${(props) => props.$hoverColor && options.hoverColor[props.$hoverColor]};
  }
`;
