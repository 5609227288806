/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useCompleteFTUEStepMutation } from '@/store/onboarding/mutations';
import { FTUEStepDto } from '@/store/user-profile/types';
import { getActiveFtueStep } from '@/components/teacher/FTUE/constants';
import { fetchExams } from '@/store/exams/queries';
import {
  commonUnregisteredLearnerRoutes,
  commonUnregisteredParentRoutes,
  commonUnregisteredTeacherRoutes,
  routes,
} from '@/components/common/ProtectedRoute/routes';
import { useUserProfileContext } from './UserProfileContext';
import { useGlobalContext } from './GlobalContext';
import { getRoleGroup } from './AuthContext/helpers';
import { useAuthContext } from './AuthContext';

type FTUEContextType = {
  FTUEsteps: FTUEStepDto[];
  activeFTUEStep: number;
  onCompleteStep: (step: number) => void;
};

export const FTUEContext = createContext<FTUEContextType>({
  FTUEsteps: [],
  activeFTUEStep: 0,
  onCompleteStep: () => {},
});

const FTUEProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const { state: user, dispatch } = useUserProfileContext();
  const groupRole = getRoleGroup(user.type);
  const { setIsRightDrawerOpen, setIsLeftDrawerOpen } = useGlobalContext();
  const [isSteps, setIsSteps] = useState(false);
  const [activeFTUEStep, setActiveFTUEStep] = useState(0);
  const FTUEsteps = useMemo(() => user.steps, [user.steps]);
  const { data: examsConfigQuery } = fetchExams(
    groupRole === 'learner' && isSteps
  );

  const firstSubjectCode = examsConfigQuery
    ?.find((e) => e.code === user?.exams?.[0]?.code)
    ?.subjects?.sort((a, b) => a?.name?.localeCompare(b?.name))?.[0]?.code;

  useEffect(() => {
    if (FTUEsteps?.length) {
      setIsSteps(true);
    }
  }, [FTUEsteps]);

  const publicRoutes = routes.public;

  const isPublic =
    publicRoutes.has(router.pathname) ||
    commonUnregisteredParentRoutes.has(router.pathname) ||
    commonUnregisteredLearnerRoutes.has(router.pathname) ||
    commonUnregisteredTeacherRoutes.has(router.pathname);

  useEffect(() => {
    if (!isSteps || isPublic || user.isCompletedOnboarding) {
      return;
    }

    const currentActiveFTUEStep = getActiveFtueStep(FTUEsteps);

    setActiveFTUEStep(currentActiveFTUEStep || 0);

    if (currentActiveFTUEStep && groupRole === 'teacher') {
      if (
        currentActiveFTUEStep === 1 ||
        currentActiveFTUEStep === 3 ||
        currentActiveFTUEStep === 5 ||
        currentActiveFTUEStep === 6
      ) {
        if (currentActiveFTUEStep === 1) {
          setIsRightDrawerOpen(false);
          setIsLeftDrawerOpen(false);
        } else {
          setIsRightDrawerOpen(true);
          setIsLeftDrawerOpen(false);
        }
        router.push({
          pathname: '/teacher/dashboard',
          query: {
            drawerType: 'teacherStudyGroups',
            selected: 'Dashboard',
            tab: 'ProgressReport',
            offset: 1,
          },
        });
      }
      if (currentActiveFTUEStep === 2) {
        setIsRightDrawerOpen(false);
        setIsLeftDrawerOpen(false);
        router.push({
          pathname: '/teacher/study-groups',
          query: {
            selected: 'Students',
            drawerType: 'idle',
            tab: 'AllStudents',
            selectedGroupCode: router.query.selectedGroupCode,
            subjectCode: router.query.subjectCode,
          },
        });
      }
      if (currentActiveFTUEStep === 3) {
        setIsRightDrawerOpen(true);
        router.push({
          pathname: '/teacher/dashboard',
          query: {
            selectedGroupCode: router.query.selectedGroupCode,
            subjectCode: router.query.subjectCode,
            drawerType: 'teacherStudyGroups',
            selected: 'Dashboard',
            tab: 'ProgressReport',
            offset: 1,
          },
        });
      }
      if (
        currentActiveFTUEStep === 6 ||
        currentActiveFTUEStep === 7 ||
        currentActiveFTUEStep === 8
      ) {
        setIsRightDrawerOpen(true);
        router.push({
          pathname: '/teacher/assignments',
          query: {
            selected: 'Assign',
            drawerType: 'idle',
            tab: 'AssignmentBuilder',
          },
        });
      }
    }

    if (currentActiveFTUEStep && groupRole === 'customer') {
      setIsRightDrawerOpen(true);
      setIsLeftDrawerOpen(false);

      if (activeFTUEStep === 3) {
        const element = document.querySelector(`[data-ftue-step="3"]`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [isSteps, isPublic]);

  useEffect(() => {
    if (!isSteps || isPublic || user.isCompletedOnboarding) {
      return;
    }

    const currentActiveFTUEStep = getActiveFtueStep(FTUEsteps);

    if (currentActiveFTUEStep && groupRole === 'learner') {
      setIsRightDrawerOpen(true);
      setIsLeftDrawerOpen(false);

      const isMainPage = router.asPath.includes('/learner/progress-report');

      if (!isMainPage) {
        router.push({
          pathname: '/',
        });
      }

      const isMainTab = !router.query.activeExam && !router.query.subjectCode;

      if ([1, 2, 3, 4, 5, 6].includes(currentActiveFTUEStep) && !isMainTab) {
        router.push({
          query: {
            ...router.query,
            activeExam: '',
            subjectCode: '',
          },
        });
      }

      if ([7, 8, 9, 10].includes(currentActiveFTUEStep)) {
        router.push({
          query: {
            ...router.query,
            expanded: router.query.exam,
            activeExam: firstSubjectCode,
            subjectCode: firstSubjectCode,
            tab: 'todo',
            isTargetSchool: false,
          },
        });
      }

      if ([11].includes(currentActiveFTUEStep)) {
        router.push({
          query: {
            ...router.query,
            expanded: router.query.exam,
            activeExam: firstSubjectCode,
            subjectCode: firstSubjectCode,
            tab: 'mock',
            isTargetSchool: false,
          },
        });
      }
    }
  }, [isSteps, firstSubjectCode, groupRole]);

  const { mutate: completeFTUEStep } = useCompleteFTUEStepMutation((data) => {
    dispatch({
      type: 'UPDATE_FTUE_STEPS',
      payload: data?.steps,
    });

    const aFTUEStep = getActiveFtueStep(data?.steps);
    setActiveFTUEStep(aFTUEStep || 0);
  });

  const onCompleteStep = async (step: number) => {
    completeFTUEStep(step);
  };

  const contextValue = useMemo(
    () => ({
      FTUEsteps,
      activeFTUEStep,
      onCompleteStep,
    }),
    [FTUEsteps, activeFTUEStep]
  );

  return (
    <FTUEContext.Provider value={contextValue}>{children}</FTUEContext.Provider>
  );
};

export const useFTUEContext = () => useContext(FTUEContext);

export { FTUEProvider };
